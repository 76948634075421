<template>
  <div>
    <vs-popup
      class="channel_settings_popup"
      :title="title"
      :active.sync="active"
    >
      <vs-row class="version-row">
				<div class="version-div">
				<!-- <vs-col vs-w="3"/>
				<vs-col vs-w="2" class="version-col"> -->
					<span class="version-span">
					{{lang.botMaker.addBot.version[languageSelected] }}:
					</span>
				<!-- </vs-col>
				<vs-col vs-w="4"> -->
         <multiselect 
						class="version-select"
            label="tag"
						:key="multiselectKey"
            :allow-empty="false"
            v-model="selectedVersion"
            :options="botVersions"
            track-by="_id"
            :close-on-select="true"
            :show-labels="false"
            @input="changeSelectedVersion"
            placeholder
        />
				</div>
				<!-- </vs-col>
				<vs-col vs-w="3"/> -->
			</vs-row>
			<vs-row>
			<KonaTabs
				v-if="selectedChannel && selectedChannel.options"
				:tabsStyle="tabsStyle">
					<KonaTab
						v-for="(option, key) in selectedChannel.options"
						:key="key"
						:type="option.type"
						:name="option.name"
						:selected="key === 0 ? true : false"><ChannelForm :version="selectedVersion._id" :channel="selectedChannel.type" :option="option" :ref="option.key"/></KonaTab>
			</KonaTabs>
			</vs-row>
    </vs-popup>
    <KonaPopup
      ref="konaPopup"
      type="warning"
      :title="lang.botMaker.addBot.botChannels.confirm.title[languageSelected]"
    >
      <span class="channel-integration-warning">
        {{ lang.botMaker.addBot.widget.openError[languageSelected] }}
        <a :href="`mailto:${MAILS.support}`" target="_blank">
          {{ MAILS.support }}
        </a>
      </span>
    </KonaPopup>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { MAILS } from '@/constants/constants'

export default {
  props: {
    channel: String,
  },
  components: {
    KonaPopup: () => import('@/components/KonaPopup.vue'),
    Multiselect: () => import('vue-multiselect'),
		KonaTabs: () => import('@/components/kona-tabs/KonaTabs.vue'),
    KonaTab: () => import('@/components/kona-tabs/KonaTab.vue'),
		ChannelForm: () => import('./ChannelForm.vue')
  },
  beforeDestroy() {
    this.active = false
  },
  data() {
    return {
			title: 'lalala',
      MAILS,
      keyBotWidget: new Date().getTime(),
			multiselectKey: new Date().getTime(),
      active: false,
      selectedVersion: null,
    }
  },
  watch: {
    active() {
      this.keyBotWidget = new Date().getTime()
    },
		selectedChannel() {
			this.title = this.selectedChannel.name;
		}
  },
  methods: {
		...mapActions('integrations', ['GET_INTEGRATIONS', 'GET_CHANNELS', 'GET_CHANNEL_OPTIONS']),
    open() {
      this.active = true
    },
    close() {
      this.active = false
    },
		switchTab() {
    },
    changeSelectedVersion() {
			console.log('VERSION', this.selectedVersion)
    }
  },
  computed: {
    ...mapGetters('bots', ['bot', 'validations', 'sectionReady']),
		...mapGetters('integrations', ['selectedChannel']),
    ...mapGetters('botMaker', [
      'bot',
      'botVersions',
      'activeVersionData',
      'activeVersion'
    ]),
		tabsStyle() {
      return `
        background: white;
        height: 50px;
        border: 3px solid transparent;
        margin-top: 0;
        border-bottom: 0;
        width: 100%;
      `
    },
    ...mapState(['lang', 'languageSelected', 'companyAuthKey', 'companyId'])
  },
	beforeMount(){
		
	},
  mounted() {
		const index = this.botVersions.findIndex(v => v._id.toString() === this.activeVersion.toString());
    this.selectedVersion = this.botVersions[index];
  }
}
</script>

<style lang="scss">
.channel_settings_popup.con-vs-popup {
	.navigation-tab {
		width: auto !important;
	}
	.version-row {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.version-select{
		min-width: 150px;
		margin-left: 5px;
	}
	.version-div {
		display: inline-flex;
		align-items: baseline;
	}
	.version-span {
		margin-right: 5px;
	}
	.vs-popup {
    // width: 500px;
	}
  .version-col {
		display: flex;
		align-items: center;
  }
	.version-span {
		margin-right: 0px;
		margin-left: auto;
	}
}
</style>
