<template>
  <div>
  <add-bot-section
    v-if="!widgetSettings"
    :title="lang.botMaker.addBot.botChannels.title[languageSelected]"
  >
    <template slot="whole-w">
      <h3 class="description">
        {{ lang.botMaker.addBot.botChannels.description[languageSelected] }}
        <KonaAlert
          v-if="!bot || !bot.service || !bot.service.id"
          icon="AlertCircleIcon"
          bg-color="rgba(var(--vs-warning), 0.15)"
          color="rgba(var(--vs-warning), 1)"
          size="small"
        >
          {{ lang.botMaker.addBot.botChannels.disclaimer[languageSelected] }}
        </KonaAlert>
      </h3>
      <div v-if="loadedChannels" class="bot-channels">
        <BotChannel
          v-for="channel in botChannels"
          :key="channel.type"
          :channel="channel"
          @openSettingsPopup="openSettingsPopup"
        />
      </div>
      <!-- <PopupBotWidgetSettings ref="PopupBotWidgetSettings" /> -->
      
      <PopupFacebookSettings
        ref="PopupFacebookSettings"
        :channel="botChannels.find(b => b.type === 'facebook')"
        @changeFacebookApiKey="updateApiKey"
        :apikey="apikey"
      />
      <PopupChannelSettings ref="PopupChannelSettings" :channel="channel" />
    </template>
  </add-bot-section>
  <WidgetSettings v-if="widgetSettings" ref="PopupBotWidgetSettings" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Helper from '../../../../../store/Helper'
import WidgetSettings from '../BotWidgetSettings/WidgetSettings.vue'
import PopupFacebookSettings from '../BotFacebookSettings/Popup.vue'
import PopupChannelSettings from './PopupChannelSettings/Popup.vue'

export default {
  name: 'BotChannels',
  data() {
    return {
      loadedChannels: false,
      apikey: '',
      channel: null,
      widgetSettings: false,
    }
  },
  components: {
    WidgetSettings,
    PopupFacebookSettings,
    PopupChannelSettings,
    AddBotSection: () => import('../Components/AddBotSection.vue'),
    BotChannel: () => import('./BotChannel.vue'),
    KonaAlert: () => import('@/components/KonaAlert.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('integrations', ['integrations', 'botChannels']),
    ...mapGetters('bots', ['bot'])
  },
  methods: {
    ...mapActions('integrations', ['GET_INTEGRATIONS', 'GET_CHANNELS', 'GET_CHANNEL_OPTIONS']),
    ...mapMutations('integrations', ['INIT_BOT_CHANNELS']),
    ...mapMutations('bots', [
      'SET_WIDGET_SETTINGS_OPEN',
    ]),
    updateApiKey(apikey) {
      this.botChannels.find(b => b.type === 'facebook').config = {}
      this.botChannels.find(b => b.type === 'facebook').config.apikey = apikey
      this.apikey = apikey
    },
    async initBotChannels() {
      const serviceId = this.$route.query.serviceId
      if (serviceId) {
        // editing bot -> update channels states
        const payload = {
          services: [serviceId]
        }
        await this.GET_CHANNELS();
        await this.GET_INTEGRATIONS(payload)

        const botChannels = []
        this.integrations.forEach(integration => {
          botChannels.push({
            ...integration,
            knownType: Helper.getKnownChannelType(integration.type)
          })
        })
        await this.INIT_BOT_CHANNELS(botChannels)
      }
      this.loadedChannels = true
    },
    openSettingsPopup(channel) {
      const serviceId = this.$route.query.serviceId;
      switch (channel) {
        case 'web':
          this.SET_WIDGET_SETTINGS_OPEN(true);
          // this.$refs.PopupBotWidgetSettings.open()
          break
        // case 'facebook':
        //   this.$refs.PopupFacebookSettings.open()
        //   break
        default:
          this.channel = channel;
          this.GET_CHANNEL_OPTIONS(this.channel);
          if (serviceId) {
            // editing bot -> update channels states
            const payload = {
              services: [serviceId]
            }
            this.GET_INTEGRATIONS(payload)
          }
          this.$refs.PopupChannelSettings.open()
          break
      }
    }
  },
  async mounted() {
    await this.initBotChannels()
    const botChannel = this.botChannels.find(b => b.type === 'facebook')
    this.apikey = botChannel && botChannel.config && botChannel.config.apikey
  }
}
</script>

<style lang="scss">
.description {
  font-size: 12px;
  margin-bottom: 10px;
}
</style>
